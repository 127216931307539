body {
  margin: 0;
  padding: 0;
  font-family: "Futura PT", sans-serif;
  overflow-x: hidden;
}

.futura-ready .hidden-font {
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
}

iframe {
  border: none;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: #0088bc;
}

/*Popover*/
.Popover.Popover-below {
  z-index: 999;
}

* {
  box-sizing: border-box;
}
