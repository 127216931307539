body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  font-family: "Futura PT", sans-serif;
  color: #212121;
  letter-spacing: 0.4px;
  background-color: #FFFFFF;
}

button {
  font-family: inherit;
}
