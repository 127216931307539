.CodeMirror pre,
.CodeMirror-linenumber {
  font-size: 12px;
}

.sandpack-File__container {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.5);
}

.sandpack-CodeMirror__container .react-codemirror2 {
  height: 100%;
}

.sandpack-File__active {
  color: rgba(255, 255, 255, 1);
}
