@import url(https://fonts.googleapis.com/css?family=Poppins);
.Popover-body {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
}

.Popover-above .Popover-tip {
  margin-top: -1px;
}

.Popover-below .Popover-tip {
  margin-bottom: -1px;
}

.Popover-tip {
  fill: #FFFFFF;
}

.Popover-tipShape {
  stroke: #bbb;
  stroke-dasharray: 0, 0, 20;
}

/**
 * Line to children
 */
.rstcustom__lineChildren {
  visibility: hidden;
}

/**
 * Other lines
 */
.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  visibility: hidden;
}

/**
 * Collapse button
 */
.rstcustom__collapseButton {
  visibility: hidden;
}

/**
 * Resize node height
 */
.rst__node {
  height: 50px !important;
}

/**
 * Make node cover remaining space to the right
 */
.rst__nodeContent {
  right: 0;
}

/**
 * Resize internal padding and disable opacity change on hover
 */
.rstcustom__rowWrapper {
  padding: 1px 10px 1px 0;
  opacity: 1 !important;
}

/**
 * Remove box shadows
 */
.rstcustom__rowContents {
  background-color: transparent;
  border: none;
}

/**
 * Row text should extend to right edge
 */
.rstcustom__rowLabel {
  width: 100%;
}

.rstamagi_viewOnly {
  margin-left: -30px;
}

/**
 * Thinner nodes during non-edit mode
 */
.rstamagi_viewOnly .rst__node {
  height: 30px !important;
}

/**
 * Remove border during non-edit mode
 */
.rstamagi_viewOnly .rstcustom__rowContents {
  border: 0;
}

/**
 * Adjust indent during non-edit mode
 */

.rstamagi_viewOnly .rst__lineBlock + .rst__nodeContent {
  left: 30px !important;
}

.rstamagi_viewOnly .rst__lineBlock + .rst__lineBlock + .rst__nodeContent {
  left: 60px !important;
}

.rstamagi_viewOnly
  .rst__lineBlock
  + .rst__lineBlock
  + .rst__lineBlock
  + .rst__nodeContent {
  left: 90px !important;
}

.rstamagi_viewOnly
  .rst__lineBlock
  + .rst__lineBlock
  + .rst__lineBlock
  + .rst__lineBlock
  + .rst__nodeContent {
  left: 120px !important;
}

.rstamagi_viewOnly
  .rst__lineBlock
  + .rst__lineBlock
  + .rst__lineBlock
  + .rst__lineBlock
  + .rst__lineBlock
  + .rst__nodeContent {
  left: 150px !important;
}

/**
 * Default react-popover container styles
 */

.amagi-popover .Popover-body {
  border: 0;
  border-radius: 0;
  overflow: visible;
}

.ace_editor {
  line-height: 1.5rem !important;
  font-size: 0.9rem !important;
}

#python-editor-container {
  display: flex;
  background-color: #282c2e;
  height: 100%;
  width: 100%;
  font-family: "Poppins", monospace;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#python-editor-input {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

#python-code-editor {
  height: 95%;
  background-color: #f9f9f9;
  overflow: auto;
}

#python-editor-output {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#python-editor-output textarea {
  width: calc(100% - 10px);
  height: 100%;
  background-color: #000000;
  padding: 5px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #f9f9f9;
  font-family: "Roboto Mono", monospace;
}

.CodeMirror pre,
.CodeMirror-linenumber {
  font-size: 12px;
}

.sandpack-File__container {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.5);
}

.sandpack-CodeMirror__container .react-codemirror2 {
  height: 100%;
}

.sandpack-File__active {
  color: rgba(255, 255, 255, 1);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Futura PT", sans-serif;
  overflow-x: hidden;
}

.futura-ready .hidden-font {
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
}

iframe {
  border: none;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: #0088bc;
}

/*Popover*/
.Popover.Popover-below {
  z-index: 999;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  font-family: "Futura PT", sans-serif;
  color: #212121;
  letter-spacing: 0.4px;
  background-color: #FFFFFF;
}

button {
  font-family: inherit;
}

