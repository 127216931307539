/**
 * Line to children
 */
.rstcustom__lineChildren {
  visibility: hidden;
}

/**
 * Other lines
 */
.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  visibility: hidden;
}

/**
 * Collapse button
 */
.rstcustom__collapseButton {
  visibility: hidden;
}

/**
 * Resize node height
 */
.rst__node {
  height: 50px !important;
}

/**
 * Make node cover remaining space to the right
 */
.rst__nodeContent {
  right: 0;
}

/**
 * Resize internal padding and disable opacity change on hover
 */
.rstcustom__rowWrapper {
  padding: 1px 10px 1px 0;
  opacity: 1 !important;
}

/**
 * Remove box shadows
 */
.rstcustom__rowContents {
  background-color: transparent;
  border: none;
}

/**
 * Row text should extend to right edge
 */
.rstcustom__rowLabel {
  width: 100%;
}

.rstamagi_viewOnly {
  margin-left: -30px;
}

/**
 * Thinner nodes during non-edit mode
 */
.rstamagi_viewOnly .rst__node {
  height: 30px !important;
}

/**
 * Remove border during non-edit mode
 */
.rstamagi_viewOnly .rstcustom__rowContents {
  border: 0;
}

/**
 * Adjust indent during non-edit mode
 */

.rstamagi_viewOnly .rst__lineBlock + .rst__nodeContent {
  left: 30px !important;
}

.rstamagi_viewOnly .rst__lineBlock + .rst__lineBlock + .rst__nodeContent {
  left: 60px !important;
}

.rstamagi_viewOnly
  .rst__lineBlock
  + .rst__lineBlock
  + .rst__lineBlock
  + .rst__nodeContent {
  left: 90px !important;
}

.rstamagi_viewOnly
  .rst__lineBlock
  + .rst__lineBlock
  + .rst__lineBlock
  + .rst__lineBlock
  + .rst__nodeContent {
  left: 120px !important;
}

.rstamagi_viewOnly
  .rst__lineBlock
  + .rst__lineBlock
  + .rst__lineBlock
  + .rst__lineBlock
  + .rst__lineBlock
  + .rst__nodeContent {
  left: 150px !important;
}

/**
 * Default react-popover container styles
 */

.amagi-popover .Popover-body {
  border: 0;
  border-radius: 0;
  overflow: visible;
}
