@import url("https://fonts.googleapis.com/css?family=Poppins");
#python-editor-container {
  display: flex;
  background-color: #282c2e;
  height: 100%;
  width: 100%;
  font-family: "Poppins", monospace;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#python-editor-input {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

#python-code-editor {
  height: 95%;
  background-color: #f9f9f9;
  overflow: auto;
}

#python-editor-output {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#python-editor-output textarea {
  width: calc(100% - 10px);
  height: 100%;
  background-color: #000000;
  padding: 5px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #f9f9f9;
  font-family: "Roboto Mono", monospace;
}
